import React, { createRef, useEffect, useRef, useState } from 'react';
import './App.css';
import Terms from './Terms';
import './firebase';
import logo from './ai_logo.png';
import loadingImg from './loading.gif';
import { createCompletion, getNumConversationsRestToday, getRecentItems, registerConversation, saveConversation } from './firebase';
import { Conversation, ConversationInfo } from '../server/src/chat';
import { getAuth } from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';

type Message = {
  role: 'user' | 'assistant';
  content: string;
  suffix?: string;
}

const getSessionItem = <T,>(key: string, defaultValue: T) => {
  const value = sessionStorage.getItem(key);
  // console.log(`session ${key}`, value);
  if (value) {
    return JSON.parse(value) as T;
  } else {
    return defaultValue;
  }
}

export type AppConversation = Partial<ConversationInfo> & {
  id: string;
  messages: Message[];
  presetIngredients?: boolean;
  finished: boolean;
};

type MessageUpdater = {
  updateMessageFromAssistant?: ((conversation: AppConversation, message: string) => void);
  updateMessageFromUser?: ((conversation: AppConversation, message: string) => void);
}

const getMessageUpdater = (status: Status, conversation: AppConversation): MessageUpdater => {
  const updater: MessageUpdater = {};
  switch (status) {
    case 'userInput':
      if (!conversation.requiredIngredients) {
        updater.updateMessageFromUser = (conversation, message) => {
          conversation.requiredIngredients = message;
        }
      } else if (!conversation.excludedIngredients) {
        updater.updateMessageFromUser = (conversation, message) => {
          conversation.excludedIngredients = message;
        }
      } else {
        updater.updateMessageFromUser = (conversation, message) => {
          const newMessages = [...conversation.messages];
          newMessages.push({ role: 'user', content: message });
          conversation.messages = newMessages;
        };
      }
      break;
    case 'loadingResponse':
      if (conversation.requiredIngredients != null && conversation.excludedIngredients == null) {
        updater.updateMessageFromAssistant = (conversation, message) => {
          conversation.ingredientsForRecipe = message;
        }
      }
      if (conversation.excludedIngredients != null) {
        updater.updateMessageFromAssistant = (conversation, message) => {
          //console.log("additional message", message);
          const suffix = "\u25a0";
          const newMessages = [...conversation.messages];
          if (newMessages.length === 0 || (newMessages[newMessages.length - 1]).role === 'user') {
            newMessages.push({ role: 'assistant', content: message, suffix });
          } else {
            newMessages[newMessages.length - 1].content = message;
          }
          conversation.messages = newMessages;
        }
      }
      break;
  }
  return updater;
}

type Status = 'init' | 'loadingRecentItems' | 'userInput' | 'loadingResponse' | 'finished' | 'aborted';

export default () => {
  const [messageInput, setMessageInput] = useState<string>('');
  //const [networkState, setNetworkState] = useState<'none' | 'posting' | 'loading'>('none');
  const [status, setStatus] = useState<Status>('init');
  const [terms, showTerms] = useState(false);
  //const [messages, setMessages] = useState<Message[]>([]);
  const initialConversation: AppConversation = {
    id: '',
    finished: false,
    messages: [],
  };
  const [conversation, setConversation] = useState<AppConversation>(initialConversation);
  const [numLeft, setNumLeft] = useState<number|null>(null);
  const [reachedLimit, setReachedLimit] = useState(false);
  const messagesEndRef = createRef<HTMLDivElement>();
  const timerRef = useRef<NodeJS.Timeout|null>(null);
  const lastConversationRef = useRef<AppConversation|null>(null);
  const lastSavedConversationRef = useRef<AppConversation|null>(null);
  const savingRef = useRef(false); // 保存中かどうか

  lastConversationRef.current = conversation;

  const messages: Message[] = [];

  const sync = () => {
    stopSync();
    const conversation = lastConversationRef.current;
    if (!savingRef.current && conversation && conversation != lastSavedConversationRef.current) {
      //console.log('saving conversation');
      savingRef.current = true;
      saveConversation(conversation).then(() => {
        //console.log("saved converation");
        if (!conversation.finished) {
          timerRef.current = setTimeout(sync, 5000);
        }
        savingRef.current = false;
      })
      .catch((error) => {
        console.error("saveConversation failed", error);
        savingRef.current = false;
      });
    }
    lastSavedConversationRef.current = conversation;
  };
  const startSync = () => {
    if (!timerRef.current && !savingRef.current) {
      sync();
    }
  }
  const stopSync = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };
  useEffect(() => stopSync, []);

  /*
  messages の概要
  [0] assistant: 食材の一覧、必ず使いたい食材はありますか？
  [1] user: 使いたい食材 (conversation.requiredIngredients)
  [2] assistant: 材料の提案、抜きたい食材はありますか？
  [3] user: 抜きたい食材 (conversation.excludedIngredients)
  [4] assistant: レシピの提案 (conversation.messages[0])
  [5] user: その後の会話 (conversation.messages[1])
  ...
  */

  if (conversation.ingredients != null) {
    messages.push(
      {
        role: 'assistant',
        content: `
        こんにちは！あなたの直近で買ったものから、今から作る料理のレシピを提案します。
        ${ conversation.presetIngredients ?
          '買った食材が見当たらなければ、家庭によくある食材を中心に組み立てます。' :
          conversation.ingredients.join('\n') }
        必ず使いたい食材はありますか？
        `
      }
    );
  }
  if (conversation.requiredIngredients != null) {
    startSync();
    messages.push({
      role: 'user',
      content: conversation.requiredIngredients
    });
  }
  if (conversation.ingredientsForRecipe != null) {
    messages.push({
      role: 'assistant',
      content: conversation.ingredientsForRecipe + 
        (conversation.excludedIngredients != null || status === 'userInput' ?
        `\nこちらでいかがでしょうか？すでに使ってしまった食材がある場合は教えてください。\n
          なければ、このレシピの詳細をお伝えします。` : '')
    });
  }
  if (conversation.excludedIngredients != null) {
    messages.push({
      role: 'user',
      content: conversation.excludedIngredients
    });
  }
  messages.push(...conversation.messages);

  if (conversation.finished) {
    sync();
  }

  //console.log('conversation', conversation);
  //console.log('messages', messages);

  const handleMessageInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageInput(event.target.value);
  };

  useEffect(() => {
    const messagesElement = document.getElementsByClassName('chat-messages')[0] as HTMLDivElement;
    const yOffset = 30;
    messagesElement.scrollTo({top: messagesEndRef.current!.offsetTop - messagesElement.offsetHeight + yOffset, behavior: 'smooth'});
  }, [conversation]);

  const postConversation = (conversation: AppConversation) => {
    const listener = {
      update: (message: string) => {
        const newConversation = Object.assign({}, conversation);
        const updater = getMessageUpdater('loadingResponse', newConversation).updateMessageFromAssistant;
        if (updater) {
          updater(newConversation, message);
        } else {
          console.error('updateMessageFromAssistant is null');
        }
        setConversation(newConversation);
      },
      done: () => {
        // console.log('done');
        setConversation((conversation) => {
          const newConversation = Object.assign({}, conversation);
          // 会話終了を判定する
          const assistantMessages = newConversation.messages
            .filter((message) => message.role === 'assistant')
            .map((message) => message.content);
          let newStatus: Status = 'userInput';
          if (newConversation.messages.length > 0 &&
            newConversation.messages[newConversation.messages.length - 1].role === 'assistant') {
            newConversation.messages[newConversation.messages.length - 1].suffix = '';
          }
          if ((assistantMessages.length >= 2 &&
              (assistantMessages[assistantMessages.length - 1].indexOf('ご利用ありがとうございました') >= 0 ||
              assistantMessages[assistantMessages.length - 2].indexOf('参考になりましたか') >= 0)) ||
              assistantMessages.length >= 8) {
            newConversation.finished = true;
            newStatus = 'finished';
            if (numLeft != null && numLeft <= 0) {
              setReachedLimit(true);
            }
            logEvent(getAnalytics(), 'finished');
          }
          setStatus(newStatus);
          return newConversation;
        });
        //setNetworkState('none');
      },
      aborted: (error: any) => {
        // console.log('abort', error);
        //setNetworkState('none');
        setStatus('aborted');
      }
    }
    createCompletion(conversation as Conversation, listener);

    // 必ず使いたい材料を入れた時点で登録しておく（回数制限にカウントされる）
    if (conversation.ingredientsForRecipe == null) {
      registerConversation(conversation.id).then(() => {
        // console.log('registered', conversation.id);
      }).catch((error) => {
        console.error('registerConversation failed', error);
      });
    }
  }

  const handleSubmitMessage = (event: React.FormEvent<HTMLFormElement>) => {
    console.log('handleSubmitMessage');
    event.preventDefault();
    if (reachedLimit) return;

    setStatus('loadingResponse');

    const newConversation = Object.assign({}, conversation);
    // user からのメッセージを更新する
    const updater = getMessageUpdater('userInput', newConversation).updateMessageFromUser;
    if (!updater) {
      console.error("updateMessageFromUser is null");
      return;
    }
    updater(newConversation, messageInput);

    // メッセージを送信する
    // console.log('handleSubmitMessage', newConversation);
    postConversation(newConversation);

    setConversation(newConversation);
    setMessageInput('');
  };

  const start = () => {
    logEvent(getAnalytics(), 'start');
    setConversation(initialConversation);
    setStatus('loadingRecentItems');
    getNumConversationsRestToday().then((num) => {
      setNumLeft(num);
      if (num <= 0) {
        setReachedLimit(true);
        setStatus('finished');
      } else {
        getRecentItems().then(({items, familyMembers, preset}) => {
          const ingredients = items.map((item) => item.name);
          const id = `${getAuth().currentUser?.uid}-${Date.now()}`;
          setStatus('userInput');
          setConversation((conversation) => 
            Object.assign({}, conversation, {
              id,
              ingredients,
              presetIngredients: preset,
              familyMembers
            }));
          });
      }
    });
  }

  useEffect(() => {
    if (status !== 'init' && status !== 'loadingRecentItems') {
      sessionStorage.setItem('conversation', JSON.stringify(conversation));
    }
  }, [conversation, status]);

  // 初期状態をロードする
  useEffect(() => {
    logEvent(getAnalytics(), 'load');
    const conversation = getSessionItem('conversation', null) as AppConversation | null;
    if (conversation && conversation.requiredIngredients != null) {
      // セッションに開始済みの conversation がある場合
      if (conversation.finished) {
        setStatus('finished');
      } else {
        setStatus('userInput');
      }
      setConversation(conversation);
      getNumConversationsRestToday().then((num) => {
        setNumLeft(num);
        if (num <= 0) {
          if (conversation.finished) {
            setReachedLimit(true);
          }
        } else if (num < 0) {
          setReachedLimit(true);
        }
      });
    } else {
      // 新しく開始する場合
      start();
    }
    return stopSync;
  }, []);

  if (terms) {
    return <Terms onBack={() => showTerms(false)} />
  } else {
    return (
      <>
        <div className="chat-wrapper">
          <div className="chat-messages">
            <div className="chat-title">
              <img src={logo} className="chat-title-logo" alt="Zaim 買い物レシピ AI" />
            </div>
            <div className="terms-link">
              <a onClick={() => showTerms(true)}>ご利用前にお読みください</a>
            </div>
            {messages.map((message: Message, index: number) => {
              let suffix = '';
              if (index === messages.length - 1 && message.role === 'assistant' && status === 'loadingResponse') {
                suffix = "\u2588";
              }
              return <div key={`message-${index}`} className="chat-message-wrapper">
                <div className={"chat-message " + message.role} key={index}>
                  {(message.content + suffix).split("\n").map((m, i) =>
                    <span key={`message-${index}-${i}`} className="text">{m}</span>
                  )}
                </div>
              </div>
            })}
            <div className="loading-indicator" style={{opacity: (status === 'loadingRecentItems' || status === 'loadingResponse') ? 1 : 0 }}>
              <img src={loadingImg} />
            </div>
            {(conversation && conversation.finished) &&
            <div className="system-message">
              <div>これでチャットは終了です</div>
            </div>
            }
            {!reachedLimit && (numLeft != null && numLeft > 0) &&
              (status === 'finished' || status === 'aborted' ||
              (status === 'userInput' && conversation.requiredIngredients)) &&
            <div className="system-message">
              <a onClick={start}>最初からやり直す</a>
            </div>
            }
            {reachedLimit && (conversation.finished || conversation.ingredients == null) &&
            <div className="system-message">
              { !conversation.finished &&
              <div>
              ごめんなさい！
              </div>
              }
              <div>
  本日は AI との会話上限を超えてしまいました。
  また明日、お試しください。
              </div>
              <div>
  毎日 12 時に制限は解除となります。
  </div>
            </div>
            }   
            <div ref={messagesEndRef} />
          </div>
        </div>
        <form className="chat-input" onSubmit={handleSubmitMessage}>
          <input
            type="text"
            placeholder="AI に話しかける…"
            value={messageInput}
            onChange={handleMessageInput}
          />
          <button type="submit" disabled={status !== 'userInput'}></button>
        </form>
      </>
    );
  }
};
