import React from 'react';
import './firebase';
import { getAuth, signInWithRedirect, GoogleAuthProvider, setPersistence, browserSessionPersistence, signInWithCustomToken, User } from 'firebase/auth';
import { initializeApp, getApp } from 'firebase/app';
import { redirectForAuthorization } from './oauth';
import axios, { AxiosResponse } from 'axios';
import { CALLBACK_URI, functionURL } from './firebase';
import qs from 'qs';
import App from './App';

export default () => {
  const [user, setUser] = React.useState<User | null>(null);
  const [error, setError] = React.useState("");
  const query = qs.parse(window.location.search.replace(/^\?/, ''));
  console.debug(`query=${JSON.stringify(query)}`);
  React.useEffect(() => {
    getAuth(getApp()).onAuthStateChanged((user) => {
      if (user) {
        // console.log("user", user);
        //setToken(user.to);
        setUser(user);
        /*
        user.getIdToken().then((token) => {
          console.log("getIdToken", token);
          setToken(token);
        });
        */
      } else {
        if (query.code == null) {
          console.log("no user");
          redirectForAuthorization()
          .catch((err) => {
            setError(String(err));
            console.log(err);
          });
          }
        }
    })
    if (query.code != null) {
      console.log('callback');
      const verifier = sessionStorage.getItem('verifier');
      sessionStorage.removeItem('verifier');
      if (verifier) {
        axios.get(functionURL('getAccessToken'), {
          params: { code: query.code, code_verifier: verifier, redirect_uri: CALLBACK_URI }
        })
        .then((r: AxiosResponse) => {
          console.debug('getAccessToken success');
          console.debug(r.data);
          if (r.data.error) {
            //setMessage(r.data.error);
            throw r.data.error;
          } else {
            return r.data;
          }
        })
        .then((data) => {
          setPersistence(getAuth(), browserSessionPersistence).then(() => {
            return signInWithCustomToken(getAuth(), data.token);
          })
          .then((cred) => {
            console.log('sign in success');
            //setToken(data.token);
            //setUid(cred.user!.uid);
            const path = sessionStorage.getItem('redirect_path') ?? '/';
            console.debug(`redirecting to ${path}`);
            window.history.pushState(true, '', path);
          });
        })
        .catch((err) => {
          console.log(err);
          setError(String(err));
        })
      } else {
        console.error("no verifier");
        setError("no verifier");
      }
    }
  }, []);
  if (user) {
    return (
      <App />
    );
  } else {
    return <div>{error}</div>;
  }
};
