import './Terms.css';
import React from 'react';

export default (props: { onBack: () => void }) => {
  return <div className="terms-wrapper">
      <h3>ご利用にあたっての注意事項</h3>
      <div className="terms-content">
        <div>

  「Zaim 買いものレシピAI」は、OpenAI が提供する「OpenAI API」を利用しています。
  サービスの利用にあったては、<a href="https://openai.com/policies/usage-policies" target="_blank">OpenAI の利用規約</a>を遵守してください。
  </div>
  <div>
  レシピの提案には、Zaim に入力いただいた家計簿の記録のデータを利用しています。提案されるレシピを含むサービス内で出力される内容には、誤った情報や有害な指示・偏った内容が含まれる可能性があります。また、サービス内で出力される内容が当社や第三者の権利を侵害するものでないことは保証しません。
  </div>
  <div>
  このサービスが、開発中のものを提供する性質上、不具合、誤作動、誤記載等が含まれる可能性があり、当社は、サービスについて何ら保証しません。また、当社は、サービスを利用したことにより利用者に生じた損害については、一切の責任を負いません。
  </div>
  <div>
  当社は、当社の判断に基づき、事前予告なしに、サービスの内容を変更することや、サービスの提供を中断または中止することがあります。
      </div>
    </div>
    <div className="terms-back">
      <a onClick={props.onBack}>一つ前に戻る</a>
    </div>
    </div>;
}
